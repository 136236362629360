export default {
  boundEng: {
    status: '',
    business: '',
    underwriter: '',
    awAnalist1: '',
    awAnalist2: '',
    underwriterMonth: '',
    cedentCompany: '',
    operatingCompany: '',
    typeBound: '',
    leader: '',
    term: '',
    rationalComments: '',
    riskProfileComments: '',
    riskProfileClause: '',
    riskProfileExposure: '',
    riskProfileHousekeeping: '',
    instalation1: null,
    instalation2: null,
    instalation3: null,
    clause1: null,
    clause2: null,
    clause3: null,
    ppw1: '',
    ppw2: '',
    ppw3: '',
  },
  boundInsurable: {
    rate: '',
    nd: '',
    premiumTiv: '',
    alopCurrency: '',
    alopCurrencyUsd: '',
    alopCurrencyRate: '',
    alopCurrencyNd: '',
    alopCurrencyPremium: '',
    totalRate: '',
    totalNd: '',
    totalPremium: '',
    typeSelect: '',
    sluLine: '',
    sluExposure: '',
    sluPremium: '',
    pml: '',
    resultPml: '',
    comments: '',
  },
  boundInsurableProp: {
    limitedInsured: '',
    limitedInsuredUsd: '',
    typeSelect: '',
    attachPoint: '',
    attachPointUsd: '',
    sluLine: '',
    sluExposure: '',
    sluPremium: '',
    applySirSelect: '',
    selfInsured: '',
  },
  boundEngCatDeductibles: [
    {
      id: '',
      location: '',
      underlyingCatSelect: '',
      underlyingCatAplicaSelect: '',
      underlyingCatValuesSelect: '',
      underlyingCatValuesSelectTwo: '',
      underlyingHidroSelect: '',
      underlyingHidroAplicaSelect: '',
      underlyingHidroValuesSelect: '',
      underlyingHidroValuesSelectTwo: '',
      coverB: null,
      coverTwoB: null,
      hidroRisk: null,
      hidroRiskTwo: null,
      alopEarthquake: null,
      alopHidro: null,
    },
  ],
  boundPropDeductibles: [
    {
      id: '',
      location: '',
      underlyingCatSelect: '',
      underlyingCatAplicaSelect: '',
      underlyingCatValuesSelect: '',
      underlyingCatValuesSelectTwo: '',
      underlyingHidroSelect: '',
      underlyingHidroAplicaSelect: '',
      underlyingHidroValuesSelect: '',
      underlyingHidroValuesSelectTwo: '',
      coverB: null,
      coverTwoB: null,
      hidroRisk: null,
      hidroRiskTwo: null,
      alopEarthquake: null,
      alopHidro: null,
    },
  ],
  boundEngDeductibles: [
    {
      id: '',
      underlyingFireSelect: '',
      underlyingFireNumberSelect: '',
      underlyingFireAplicaSelect: '',
      underlyingFireValuesSelect: '',
      underlyingFireAmount: '',
      underlyingFireValuesSelectTwo: '',
      underlyingFireAmountTwo: '',
      underlyingFireText: '',
      search: '',
      deductibleId: '',
    },
  ],
  boundSublimes: {
    id: '',
    sublimits1: '',
    sublimits2: '',
    sublimitsValue1: '',
    sublimitsValue2: '',
    location: '',
  },
  boundSublimesProp: [
    {
      id: '',
      sublimits1: '',
      sublimits2: '',
      sublimitsValue1: '',
      sublimitsValue2: '',
      location: '',
    },
  ],
  boundSublimitsEng: [
    {
      id: '',
      endosoSelect: '',
      endosoText: '',
      endosoUsd: '',
    },
  ],
  boundSublimitsProp: [
    {
      id: '',
      otherDeductiblesSelect: '',
      otherDeductiblesValue: '',
      otherDeductiblesValueUsd: '',
      sublimeId: '',
    },
  ],
  mliv: {
    damage: '',
    bi: '',
    stocks: '',
    damageUsd: '',
    biUsd: '',
    stocksUsd: '',
    total: '',
    totalUsd: '',
  },
  boundPml: {
    pmlDamage: '',
    pmlBi: '',
    pmlStocks: '',
    pmlDamageUsd: '',
    pmlBiUsd: '',
    pmlStocksUsd: '',
    pmlComments: '',
    pmlTotal: '',
    pmlTotalUsd: '',
  },
  boundClaims: {
    date_one: null,
    date_two: null,
    id: null,
    amount_one: null,
    amount_two: null,
    select_one: null,
    select_two: null,
  },
};
