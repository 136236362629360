import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outter-wrapper"},[_c('div',{staticClass:"endorsement-wrapper"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"detail-date"},[_c('div',{staticClass:"table-title-detail table-title-detail--large"},[_vm._v(" Detail ")]),_c('div',{staticClass:"input-col"},[_c('div',{staticClass:"input-cont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Premium payment date","readonly":"","disabled":""},model:{value:(_vm.premiumPaymentDate),callback:function ($$v) {_vm.premiumPaymentDate=$$v},expression:"premiumPaymentDate"}},'v-text-field',attrs,false),on))]}}])})],1)]),_c('div',{staticClass:"input-col"},[_c('div',{staticClass:"input-cont"},[_c(VAutocomplete,{attrs:{"label":"Clause","items":_vm.clauseList,"item-value":"clause","item-text":"clause","disabled":""},model:{value:(_vm.clause),callback:function ($$v) {_vm.clause=$$v},expression:"clause"}})],1)])]),_c('div',{staticClass:"head-items-cont-checkbox"},[_c('div',{staticClass:"endorsement-title",staticStyle:{"padding":"20px"}},[_vm._v(" Admitted premium ")]),_c('div',{staticClass:"endorsement-title",staticStyle:{"padding":"20px"}},[_c(VCheckbox,{model:{value:(_vm.showTable),callback:function ($$v) {_vm.showTable=$$v},expression:"showTable"}})],1)]),_c('div',{staticClass:"table-container input-row justify-center"},[_vm._m(1),_vm._l((_vm.detailValues),function(element){return _c('div',{staticClass:"content-col"},[_c('div',{staticClass:"col-title-tall"},[_vm._v(_vm._s(element.title))]),_c('div',{staticClass:"test"},[_vm._l((element.datas),function(item){return [(item.id === 1)?_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"table-title-left"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"table-title-space-mid"}),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"inner-col wide-col"},[_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.damage = _vm.changeHandler(
                            item.id,
                            item.damage,
                            'damage'
                          );
                        }},model:{value:(item.damage),callback:function ($$v) {_vm.$set(item, "damage", $$v)},expression:"item.damage"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.bi = _vm.changeHandler(
                            item.id,
                            item.bi,
                            'bi'
                          );
                        }},model:{value:(item.bi),callback:function ($$v) {_vm.$set(item, "bi", $$v)},expression:"item.bi"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.stocks = _vm.changeHandler(
                            item.id,
                            item.stocks,
                            'stocks'
                          );
                        }},model:{value:(item.stocks),callback:function ($$v) {_vm.$set(item, "stocks", $$v)},expression:"item.stocks"}})],1),_c('div',{staticClass:"table-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":true},model:{value:(_vm.premiumTotalComputed),callback:function ($$v) {_vm.premiumTotalComputed=$$v},expression:"premiumTotalComputed"}})],1)])])]):_vm._e()]}),_vm._l((element.datas),function(item){return [(item.id === 2)?_c('div',{key:item.id,staticClass:"table-col"},[_c('div',{staticClass:"table-title-right"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"table-title-space-mid"}),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"inner-col wide-col"},[_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":item.name == 'Original Currency',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.damage = _vm.changeHandler(
                            item.id,
                            item.damage,
                            'damage'
                          );
                        }},model:{value:(item.damage),callback:function ($$v) {_vm.$set(item, "damage", $$v)},expression:"item.damage"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":item.name == 'Original Currency',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.bi = _vm.changeHandler(
                            item.id,
                            item.bi,
                            'bi'
                          );
                        }},model:{value:(item.bi),callback:function ($$v) {_vm.$set(item, "bi", $$v)},expression:"item.bi"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":item.name == 'Original Currency',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.stocks = _vm.changeHandler(
                            item.id,
                            item.stocks,
                            'stocks'
                          );
                        }},model:{value:(item.stocks),callback:function ($$v) {_vm.$set(item, "stocks", $$v)},expression:"item.stocks"}})],1),_c('div',{staticClass:"table-input"},[_c('currency-input',{attrs:{"prefix":_vm.typeEndorsement == 'Exclusion Risk' ? '-' : '',"options":_vm.currencyOptions,"disabled":true},model:{value:(_vm.sluTotalComputed),callback:function ($$v) {_vm.sluTotalComputed=$$v},expression:"sluTotalComputed"}})],1)])])]):_vm._e()]})],2)])})],2)])]),_c(VDialog,{attrs:{"persistent":"","width":"auto"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"pb-3",attrs:{"id":"card-eye"}},[_c(VCardTitle,{staticClass:"font-weight-bold text-h5"},[_vm._v("Changes admitted premium")]),_c(VDivider,{attrs:{"id":"divisor"}}),_c('div',{staticClass:"mb-8",attrs:{"id":"border-blue"}},[_c(VCardText,{staticClass:"font-weight-bold text-h6 blue-text"},[_vm._v(" If you continue, the data entered in the admitted premium table will be taken. ")])],1),_c('div',{staticClass:"stepper-btn mt-7 mb-3 d-flex justify-space-around align-center"},[_c(VBtn,{staticClass:"blue-btn",attrs:{"text":"","rounded":"","large":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Accept ")]),_c(VBtn,{staticClass:"clear-btn",attrs:{"outlined":"","rounded":"","large":"","color":"#003D6D"},on:{"click":_vm.cancelModifyTable}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"head-items-cont"},[_c('div',{staticClass:"endorsement-title",staticStyle:{"padding":"20px"}},[_vm._v(" New Total Insured Value ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title-col"},[_c('div',{staticClass:"table-title-space"}),_c('div',{staticClass:"table-title-space"}),_c('div',{staticClass:"table-title-space-mid"}),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Damage")]),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Bi")]),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Stocks")]),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Total")])])}]

export { render, staticRenderFns }