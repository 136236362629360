export default {
  quotation: {
    insuredName: '',
    currency: '',
    exchangeRate: '0',
    inceptionDate: '',
    expiryDate: '',
    typeQuotation: 1,
    term: null,
    tivTarifadorSelect: null,
    catRate: '',
    premiumRate: null
  },
  deductions: {
    brokerage: null,
    fronting: null,
    eng: null,
    taxes: null,
    premiumReserve: null,
    lta: null,
    others: null,
    deductionType: 1,
    excess: null,
  },
  tiv: {
    propertyDamage: '',
    propertyDamageUsd: '',
    businessInterruption: '',
    businessInterruptionUsd: '',
    stock: '',
    stockUsd: '',
    total: '$0',
    totalUsd: '$0',
    porcentaje: '',
    rate: null,
  },
  premium: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    propertyDamageRate: null,
    businessInterruptionRate: null,
    stockRate: null,
    totalInsured: null,
    totalUsd: null,
    porcentaje: '',
    propertyDamageUsd: null,
    businessInterruptionUsd: null,
    stockUsd: null,
  },
  tivNon: {
    propertyDamage: null,
    businessInterruption: null,
    stock: null,
    stockPercentaje: null,
    propertyDamageUsd: null,
    businessInterruptionUsd: null,
    stockUsd: null,
    total: null,
    totalUsd: null,
    rate: null,
  },
  premiumNon: {
    rate: null,
    rateUsd: null,
    pml: null,
    pmlUsd: null,
    totalPremium: null,
    totalPremiumUsd: null,
  },
  toBeDefined: {
    limitSlu: '',
    limitSluUsd: '',
    premiumSlu: '',
    premiumSluUsd: '',
    limitedInsured: '',
    limitedInsuredUsd: '',
    sluShare: '',
  },
};
