import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Cont"},[_c('div',{staticClass:"InputsCont d-flex flex-wrap align-start"},[_vm._l((_vm.paymentsWarranty),function(payment,index){return _c('div',{key:index,staticClass:"Line d-flex justify-space-between align-center"},[_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"label":'Installment ' + (index + 1),"type":"number"},on:{"change":function($event){return _vm.updatePayment({
          id: payment.id,
          column: 'installment',
          value: payment.installment,
          index: payment.index
        })}},model:{value:(payment.installment),callback:function ($$v) {_vm.$set(payment, "installment", $$v)},expression:"payment.installment"}})],1),_c('div',{staticClass:"Row"},[_c(VTextField,{attrs:{"label":"Percentage","type":"number"},on:{"change":function($event){return _vm.updatePayment({
          id: payment.id,
          column: 'percent',
          value: payment.percent,
          index: payment.index
        })}},model:{value:(payment.percent),callback:function ($$v) {_vm.$set(payment, "percent", $$v)},expression:"payment.percent"}})],1),_c('div',{staticClass:"Row"},[_c(VMenu,{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"auto","content-class":"elevation-3","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"PPW Due Date"},model:{value:(payment.date),callback:function ($$v) {_vm.$set(payment, "date", $$v)},expression:"payment.date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(payment.showCalendar),callback:function ($$v) {_vm.$set(payment, "showCalendar", $$v)},expression:"payment.showCalendar"}},[_c(VDatePicker,{attrs:{"no-title":"","color":"#003D6D"},on:{"change":function($event){return _vm.updatePayment({
            id: payment.id,
            column: 'ppw_date',
            value: payment.date,
            index: payment.index
          })},"input":function($event){payment.showCalendar = false}},model:{value:(payment.date),callback:function ($$v) {_vm.$set(payment, "date", $$v)},expression:"payment.date"}})],1)],1),_c('div',{staticClass:"Row"},[_c(VSelect,{attrs:{"label":"Clause","item-value":"id","item-text":"clause","items":_vm.clauseList},on:{"change":function($event){return _vm.updatePayment({
          id: payment.id,
          column: 'id_payment_clause',
          value: payment.idClause + '',
          index: payment.index
        })}},model:{value:(payment.idClause),callback:function ($$v) {_vm.$set(payment, "idClause", $$v)},expression:"payment.idClause"}})],1),_c('div',{staticClass:"remove-button"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){[_vm.removePaymentWarranty(payment.index), _vm.deletePayment(payment.id, payment.index)]}}},[_c(VIcon,[_vm._v(" mdi-delete ")])],1)],1)])}),_c('div',{staticClass:"finishButtonCont d-flex justify-start align-center"},[_c(VBtn,{staticClass:"finishBtn",attrs:{"disabled":_vm.addPaymentDisabled,"rounded":"","large":"","text":""},on:{"click":function($event){return _vm.addPaymentWarranty()}}},[_vm._v(" Add New ")])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }