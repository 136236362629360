export default {
  endorsements: [],
  movementsList: [
    {
      id: 1,
      name: 'Increase Insurable Value / Inclusion Risk',
    },
    {
      id: 2,
      name: 'Reduction Of Insurable Value / Risk Exclusion',
    },
    {
      id: 3,
      name: 'Movement Without Premium',
    },
    {
      id: 4,
      name: 'Change Of Share',
    },
    {
      id: 5,
      name: 'Internal Adjustment',
    },
    {
      id: 6,
      name: 'Extra something',
    },
  ],
  newInsurancePolicy: [
    {
      id: '',
      damageTiv: '',
      damageRate: '',
      alopTiv: '',
      alopRate: '',
      damagePremium: '',
      alopPremium: '',
      damageShare: '',
      alopShare: '',
      damageBrokerage: '',
      alopBrokerage: '',
      damageTaxes: '',
      alopTaxes: '',
      damageEngFee: '',
      alopEngFee: '',
      damageFrontingFee: '',
      alopFrontingFee: '',
      damagePremiumReserve: '',
      alopPremiumReserve: '',
      damageNet: '',
      alopNet: '',
      damageNetPremium: '',
      alopNetPremium: '',
      tivTotal: '',
      rateTotal: '',
      premiumTotal: '',
      shareTotal: '',
      brokerageTotal: '',
      taxesTotal: '',
      engFeeTotal: '',
      frontingFeeTotal: '',
      premiumReserveTotal: '',
      netTotal: '',
      netPremiumTotal: '',
      effectiveDateMovement: '',
      movementEndDate: '',
    },
  ],
  increaseInsurableRisk: [
    {
      id: '',
      movementDamage: '',
      movementAlop: '',
      movementStocks: '',
      totalDamage: '',
      totalAlop: '',
      damageTiv: '',
      damageTivMovement: '',
      damageRate: '',
      damageReserve: '',
      alopTiv: '',
      alopTivMovement: '',
      alopRate: '',
      alopReserve: '',
      damagePremium: '',
      damagePremiumSlu: '',
      alopPremium: '',
      alopPremiumSlu: '',
      damageShare: '',
      alopShare: '',
      damageBrokerage: '',
      alopBrokerage: '',
      damageTaxes: '',
      alopTaxes: '',
      damageEngFee: '',
      alopEngFee: '',
      damageFrontingFee: '',
      alopFrontingFee: '',
      damageNet: '',
      alopNet: '',
      damageNetPremium: '',
      alopNetPremium: '',
      tivTotal: '',
      tivMovementTotal: '',
      rateTotal: '',
      premiumTotal: '',
      premiumSluTotal: '',
      shareTotal: '',
      brokerageTotal: '',
      taxesTotal: '',
      engFeeTotal: '',
      frontingFeeTotal: '',
      reserveTotal: '',
      netTotal: '',
      netPremiumTotal: '',
      effectiveDateMovement: '',
      movementEndDate: '',
      premiumPaymentDate: '',
      signature: '',
      support: '',
    },
  ],
  reductionOfInsurableValue: [
    {
      id: '',
      movementDamage: '',
      movementAlop: '',
      movementStocks: '',
      totalDamage: '',
      totalAlop: '',
      damageTiv: '',
      damageTivMovement: '',
      damageRate: '',
      damageReserve: '',
      alopTiv: '',
      alopTivMovement: '',
      alopRate: '',
      alopReserve: '',
      damagePremium: '',
      damagePremiumSlu: '',
      alopPremium: '',
      alopPremiumSlu: '',
      damageShare: '',
      alopShare: '',
      damageBrokerage: '',
      alopBrokerage: '',
      damageTaxes: '',
      alopTaxes: '',
      damageEngFee: '',
      alopEngFee: '',
      damageFrontingFee: '',
      alopFrontingFee: '',
      damageNet: '',
      alopNet: '',
      damageNetPremium: '',
      alopNetPremium: '',
      tivTotal: '',
      tivMovementTotal: '',
      rateTotal: '',
      premiumTotal: '',
      premiumSluTotal: '',
      shareTotal: '',
      brokerageTotal: '',
      taxesTotal: '',
      engFeeTotal: '',
      frontingFeeTotal: '',
      reserveTotal: '',
      netTotal: '',
      netPremiumTotal: '',
      effectiveDateMovement: '',
      movementEndDate: '',
      premiumPaymentDate: '',
      brokerage: '',
      taxes: '',
      frontingFee: '',
      engFee: '',
      signature: '',
      support: '',
    },
  ],
  movementWithoutPremium: [
    {
      id: '',
      effectiveDateMovement: '',
      movementEndDate: '',
      pmlComments: '',
      signature: '',
      support: '',
    },
  ],
  internalAdjustment: [
    {
      id: '',
      effectiveDateMovement: '',
      movementEndDate: '',
      pmlComments: '',
    },
  ],
  changeOfShare: [
    {
      id: '',
      movementDamage: '',
      movementAlop: '',
      movementStocks: '',
      newShare: '',
      totalDamage: '',
      totalAlop: '',
      damageTiv: '',
      damageTivMovement: '',
      damageRate: '',
      damageReserve: '',
      alopTiv: '',
      alopTivMovement: '',
      alopRate: '',
      alopReserve: '',
      damagePremium: '',
      alopPremium: '',
      damageShare: '',
      alopShare: '',
      damageBrokerage: '',
      alopBrokerage: '',
      damageTaxes: '',
      alopTaxes: '',
      damageEngFee: '',
      alopEngFee: '',
      damageFrontingFee: '',
      alopFrontingFee: '',
      damageNet: '',
      alopNet: '',
      damageNetPremium: '',
      alopNetPremium: '',
      tivTotal: '',
      tivMovementTotal: '',
      rateTotal: '',
      premiumTotal: '',
      shareTotal: '',
      brokerageTotal: '',
      taxesTotal: '',
      engFeeTotal: '',
      frontingFeeTotal: '',
      reserveTotal: '',
      netTotal: '',
      netPremiumTotal: '',
      effectiveDateMovement: '',
      movementEndDate: '',
      premiumPaymentDate: '',
      brokerage: '',
      taxes: '',
      frontingFee: '',
      engFee: '',
      signature: '',
      support: '',
    },
  ],
  endorsementsSummary: [
    {
      id: '',
      movementDamage: '',
      movementAlop: '',
      movementStocks: '',
      newShare: '',
      totalDamage: '',
      totalAlop: '',
      damageTiv: '',
      damageTivMovement: '',
      damageRate: '',
      damageReserve: '',
      alopTiv: '',
      alopTivMovement: '',
      alopRate: '',
      alopReserve: '',
      damagePremium: '',
      alopPremium: '',
      damageShare: '',
      alopShare: '',
      damageBrokerage: '',
      alopBrokerage: '',
      damageTaxes: '',
      alopTaxes: '',
      damageEngFee: '',
      alopEngFee: '',
      damageFrontingFee: '',
      alopFrontingFee: '',
      damageNet: '',
      alopNet: '',
      damageNetPremium: '',
      alopNetPremium: '',
      tivTotal: '',
      tivMovementTotal: '',
      rateTotal: '',
      premiumTotal: '',
      shareTotal: '',
      brokerageTotal: '',
      taxesTotal: '',
      engFeeTotal: '',
      frontingFeeTotal: '',
      reserveTotal: '',
      netTotal: '',
      netPremiumTotal: '',
      effectiveDateMovement: '',
      movementEndDate: '',
      premiumPaymentDate: '',
      brokerage: '',
      taxes: '',
      frontingFee: '',
      engFee: '',
    },
  ],
};
