import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"CounterContainer"},[(_vm.numNotifications > 0)?_c('div',{staticClass:"CounterContainer__Counter"},[_c('span',{staticClass:"CounterContainer__Counter--Number"},[_vm._v(_vm._s(_vm.numNotifications))])]):_vm._e(),_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-bell-outline")])],1)],1)]}}])},[(_vm.numNotifications > 0)?_c(VList,{staticClass:"NotificationsList"},_vm._l((_vm.notificationsToValidate),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.$router.push({name:'Dashboard'})}}},[_c('div',{staticClass:"NotificationsList__Notification"},[_c('div',{staticClass:"NotificationsList__Content"},[_c('div',{staticClass:"NotificationsList__Item"},[_c('strong',[_vm._v("id:")]),_vm._v(" "+_vm._s(item.id)+" "),_c('strong',[_vm._v("reference:")]),_vm._v(" "+_vm._s(item.reference)+" "),_c('strong',[_vm._v("status:")]),_vm._v(" "+_vm._s(item.status)+" ")])])])])}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }