import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-close-account d-flex justify-center align-center",class:_vm.classRounded ? 'button-submit--outlined' : ''},[_c(VMenu,{attrs:{"top":_vm.top,"left":_vm.top? false: true,"z-index":"3000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"activateButton",attrs:{"rounded":"","text":"","large":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_c('div',{staticClass:"ButtonText"},[_vm._v(" "+_vm._s(_vm.text ? _vm.text : "Close Account")+" ")]),_c(VIcon,{attrs:{"color":_vm.iconColor}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)]}}])},[_c(VList,{staticClass:"pl-5 pr-5"},[(_vm.header!=='')?_c('div',{staticClass:"headerClose d-flex justify-start align-center"},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),_vm._l((_vm.closeAccountData),function(item,index){return _c('div',{key:index,staticClass:"button",on:{"click":function($event){return _vm.CloseAccount(item.id)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.reasonText || item.description)+" ")])],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }