var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"NewRenewalComponent d-flex justify-center align-center mt-2"},[_c('router-link',{staticClass:"NewRenewalTextContent LeftButton",class:[
    _vm.currentRouteName == 'Subs submission' || 
    _vm.currentRouteName == 'Edit Subs submission' 
    ? 'active shadow1' : ''
  ],attrs:{"to":"/underwriting/submission"}},[_c('div',{staticClass:"NewRenSn",class:[
        _vm.currentRouteName == 'Subs submission' || 
        _vm.currentRouteName == 'Edit Subs submission' 
        ? 'bottomline' : '']},[_vm._v(" New Account ")])]),_c('router-link',{staticClass:"NewRenewalTextContent RightButton",class:[
    _vm.currentRouteName == 'Accounts to be renewed' ||
    _vm.currentRouteName == 'Renewal subscription'
    ? 'active shadow2' : ''
  ],attrs:{"to":"/renewals/accounts"}},[_c('div',{staticClass:"NewRenSn",class:[
    _vm.currentRouteName == 'Accounts to be renewed' ||
    _vm.currentRouteName == 'Renewal subscription' ? 'bottomline' : ''
    ]},[_vm._v(" Account Renewal ")])]),_c('CloseAccount')],1)}
var staticRenderFns = []

export { render, staticRenderFns }