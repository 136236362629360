import { render, staticRenderFns } from "./AppEmailEditor.vue?vue&type=template&id=707a556e&scoped=true&"
import script from "./AppEmailEditor.vue?vue&type=script&lang=js&"
export * from "./AppEmailEditor.vue?vue&type=script&lang=js&"
import style0 from "./AppEmailEditor.vue?vue&type=style&index=0&id=707a556e&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "707a556e",
  null
  
)

export default component.exports