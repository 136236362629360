import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VStepper } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outter-wrapper"},[_c(VStepper,{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c('div',{staticClass:"endorsement-wrapper"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"head-items-cont"},[_c('div',{staticClass:"endorsement-title",staticStyle:{"padding":"20px"}},[_vm._v(" Admited premium ")])]),_c('div',{staticClass:"table-container input-row justify-center"},[_c('div',{staticClass:"title-col"},[_c('div',{staticClass:"table-title-space"}),_c('div',{staticClass:"table-title-space"}),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Damage")]),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Bi")]),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Stocks")]),_c('div',{staticClass:"col-subtitle-tall"},[_vm._v("Total")])]),_vm._l((_vm.detailValues),function(item){return [(item.id === 1)?_c('div',{staticClass:"table-col"},[_c('div',{staticClass:"col-table-title"},[_vm._v("Total premium")]),_c('div',[_c('div',{staticClass:"table-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"inner-col wide-col"},[_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.premiumDamage = _vm.changeHandler(
                            item.id,
                            item.premiumDamage,
                            'premiumDamage'
                          );
                        }},model:{value:(item.premiumDamage),callback:function ($$v) {_vm.$set(item, "premiumDamage", $$v)},expression:"item.premiumDamage"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.premiumBi = _vm.changeHandler(
                            item.id,
                            item.premiumBi,
                            'premiumBi'
                          );
                        }},model:{value:(item.premiumBi),callback:function ($$v) {_vm.$set(item, "premiumBi", $$v)},expression:"item.premiumBi"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.premiumStocks = _vm.changeHandler(
                            item.id,
                            item.premiumStocks,
                            'premiumStocks'
                          );
                        }},model:{value:(item.premiumStocks),callback:function ($$v) {_vm.$set(item, "premiumStocks", $$v)},expression:"item.premiumStocks"}})],1),_c('div',{staticClass:"table-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":true},model:{value:(_vm.premiumTotalComputed),callback:function ($$v) {_vm.premiumTotalComputed=$$v},expression:"premiumTotalComputed"}})],1)])])])]):_vm._e()]}),_vm._l((_vm.detailValues),function(item){return [(item.id === 1)?_c('div',{key:item.id,staticClass:"table-col"},[_c('div',{staticClass:"col-table-title"},[_vm._v("Premium SLU")]),_c('div',[_c('div',{staticClass:"table-title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"input-row"},[_c('div',{staticClass:"inner-col wide-col"},[_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.sluDamage = _vm.changeHandler(
                            item.id,
                            item.sluDamage,
                            'sluDamage'
                          );
                        }},model:{value:(item.sluDamage),callback:function ($$v) {_vm.$set(item, "sluDamage", $$v)},expression:"item.sluDamage"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.sluBi = _vm.changeHandler(
                            item.id,
                            item.sluBi,
                            'sluBi'
                          );
                        }},model:{value:(item.sluBi),callback:function ($$v) {_vm.$set(item, "sluBi", $$v)},expression:"item.sluBi"}})],1),_c('div',{staticClass:"table-input blue-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":item.name == 'USD',"readonly":!_vm.showTable},on:{"blur":function ($event) {
                          item.sluStocks = _vm.changeHandler(
                            item.id,
                            item.sluStocks,
                            'sluStocks'
                          );
                        }},model:{value:(item.sluStocks),callback:function ($$v) {_vm.$set(item, "sluStocks", $$v)},expression:"item.sluStocks"}})],1),_c('div',{staticClass:"table-input"},[_c('currency-input',{attrs:{"options":_vm.currencyOptions,"disabled":true},model:{value:(_vm.sluTotalComputed),callback:function ($$v) {_vm.sluTotalComputed=$$v},expression:"sluTotalComputed"}})],1)])])])]):_vm._e()]})],2)])])]),_c(VDialog,{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{staticClass:"pb-3",attrs:{"id":"card-eye"}},[_c(VCardActions,{staticClass:"px-3 pb-3"},[_c(VFlex,{attrs:{"text-xs-right":""}},[_c(VBtn,{attrs:{"color":"orange","dark":"","small":"","absolute":"","fab":"","right":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1)],1),_c(VCardTitle,{staticClass:"font-weight-bold text-h5"},[_vm._v("Changes admitted premium")]),_c(VDivider,{attrs:{"id":"divisor"}}),_c('div',{staticClass:"mb-8",attrs:{"id":"border-blue"}},[_c(VCardText,{staticClass:"font-weight-bold text-h6 blue-text"},[_vm._v(" If you continue, the data entered in the admitted premium table will be taken. ")])],1),_c('div',{staticClass:"stepper-btn mt-7 mb-3 d-flex justify-space-around align-center"},[_c(VBtn,{staticClass:"blue-btn",attrs:{"text":"","rounded":"","large":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Accept ")]),_c(VBtn,{staticClass:"clear-btn",attrs:{"outlined":"","rounded":"","large":"","color":"#003D6D"},on:{"click":_vm.cancelModifyTable}},[_vm._v(" Cancel ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }