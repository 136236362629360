import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-decoration"},[_c('div',{staticClass:"create-claim"},[_c('div',{staticClass:"account"},[_c('h2',[_vm._v(_vm._s(_vm.claimUserName))])]),_c('div',{staticClass:"button-cont"},[_c(VBtn,{staticClass:"btn",attrs:{"rounded":"","large":"","color":"#003D6D","disabled":_vm.disabledSelect}},[_vm._v(" Create claim ")])],1)]),_c('div',{staticClass:"cards_claims"},_vm._l((_vm.listClaims),function(claims,index){return _c(VCard,{key:claims.id,staticClass:"margin_cards",attrs:{"width":"24%","height":"12.5rem"}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-wrap text-h6"},[_vm._v("Claim "+_vm._s(claims.id))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm._f("formattedDate")(claims.updated_at)))])],1)],1),_c(VCardActions,[_c(VBtn,{staticClass:"color_claims mx-auto",attrs:{"width":"12rem","outlined":"","rounded":"","text":"","color":"white"},on:{"click":function($event){return _vm.backHistoryTableToId(claims.id)}}},[_vm._v(" See claim ")])],1)],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }