export default {
  createUserData: {
    userSignature: "",
    userLastName: "",
    userAddress: "",
    userCountry: "",
    userCompany: "",
    userPhone: "",
    userEmail: "",
    userName: "",
    userRole: "",
  },
  submittedCreateSluUser: false,
};
