export default {
  ratesObj: {
    eqRate1: '',
    hydro1: '',
    flood1: '',
    other1: '',

    eqRate2: '',
    hydro2: '',
    flood2: '',
    other2: '',

    eqRate3: '',
    hydro3: '',
    flood3: '',
    other3: '',

    eqRate4: '',
    hydro4: '',
    flood4: '',
    other4: '',

    eqRateSelect: '',
    hydroSelect: '',
    floodSelect: '',
    otherSelect: '',
  },
  catRatesGroup: [
    {
      id: '',
      earthquake: '',
      hidro: '',
      flood: '',
      other: '',
      earthquakeRate: '',
      windRate: '',
      floodRate: '',
      otherRate: '',
      country: '',
      rate: '',
    },
  ],
  nonRates: [
    {
      id: '',
      activity: '',
      activityRate: '',
      rate: '',
      credit: '',
      reason: '',
      finalRate: '',
      premiumRate: '',
    },
  ],
  alopRates: {
    month: '',
    credit: '',
    floodRate: '',
    reasonsSelect: '',
    alopSelect: '',
    factorAlop: '',
    finalRate: '',
  },
  nonCatLarge: {
    rateSize: '',
    rateTime: '',
    credit: '',
    contractorSelect: '',
    categorySelect: '',
    rateContractor: '',
    typeConstructorSelect: '',
    ratetypeConstructor: '',
    testingSelect: '',
    ratetesting: '',
    equipmentInstallationSelect: '',
    rateequipmentInstallation: '',
    thirdPartyLiabilitySelect: '',
    ratethirdPartyLiability: '',
    firstyearOpSelect: '',
    ratefirstyearOp: '',
    maintenancePeriodSelect: '',
    ratemaintenancePeriod: '',
    inlandTransitSelect: '',
    rateinlandTransit: '',
    srccSelect: '',
    rateSrcc: '',
    errorsDesignSelect: '',
    rateerrorsDesign: '',
    reasonsSelect: '',
    sumMonthlyRate: '',
    finalRate: '',
  },
};
