export default {
  underwriters: [],
  status_eng: [],
  deductions_type: [],
  loss_type: [],
  underwriter_month: [],
  type_coverages: [],
  underlying_cats: [],
  underlying_cat_aplica: [],
  underlying_cat_values: [],
  underlying_fire: [],
  underlying_fire_aplica: [],
  sublimits: [],
  endoso: [],
  other_deductibles: [],
  type_clause: [],
  exposure: [],
  housekeeping: [],
  apply_sir: [],
  business_bound: [],
  underlying_fire_quotator: [],
  razon_claim: [],
};
