export default {
  subscription: {
    table:{
      list:[],
      limit:10,
      offset: 0,
      query: '',
      filter: '',
      filterSearch: 's.reference'
    }
  }
};
