export async function getFiles() {
  return [{
    fileId: 1,
    fileName: 'File 1',
    fileDownloadLink: 'dummylink',
    loaded: true,
    error: false,
    errorMessage: 'Error file 1',
    loading: false,
    fileType: '',
    fileTypeMessage: 'Download',
  },
 
  ]
}
