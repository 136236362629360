export default {
  emailTemplates: [],
  currentEmailTemplate: "",
  currentTemplateRiskID: "",
  selectedLang: "en",
  htmlTemplate: '',
  ccoEmail: "",
  ccEmail: "",
  lang: "",
  emailTemplatesQuotation: [],
  ccParticipants:[]
};
