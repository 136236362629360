export const BarNavData = () => {
	return [
		{
			id:1,
			text:'Registered accounts',
			path:'#',
			active:true
		},
		{
			id:2,
			text:'Subscription',
			path:'/subscription',
			active:false
		}
	]
}