export const GetCloseAccount = () => {
	return [
		{
			id: 1,
			reasonText: 'Option 1'
		},
		{
			id: 1,
			reasonText: 'Option 2'
		},
		{
			id: 1,
			reasonText: 'Option 3'
		},
		{
			id: 1,
			reasonText: 'Option 4'
		}
	]
}