export function getReinsurer () {
    return [{
      id: 1,
      name: ' ',
      line:' ',
      exposure:' ',
      premiumLine:' ',
      totalPremium:8925000,
      reinsurerPremium:20,
      DICReinsurer:'Yes',
      sluLine: 59.9,
      support:8925000,
      premium:8925000,
      sluLine2:59.9,
      support2:8925000,
      premium2:8925000,
          },
    {
      id: 2,
      name: ' ',
      line:' ',
      exposure:' ',
      premiumLine:' ',
      totalPremium:8925000,
      reinsurerPremium:20,
      DICReinsurer:'Yes',
      sluLine: 59.9,
      support:8925000,
      premium:8925000,
      sluLine2:59.9,
      support2:8925000,
      premium2:8925000,
    },
    {
      id: 3,
      name: ' ',
      line:' ',
      exposure:' ',
      premiumLine:' ',
      totalPremium:8925000,
      reinsurerPremium:20,
      DICReinsurer:'Yes',
      sluLine: 59.9,
      support:8925000,
      premium:8925000,
      sluLine2:59.9,
      support2:8925000,
      premium2:8925000,
    }
    ]
  }
  