export const GetArrayFilesSummary = () => {
	return [
		{
			id: 1,
      fileName: 'New Document',
      downloadLink: '#',
      errorMessage: '',
      status: 'loaded',
		}
	]
}