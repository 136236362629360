import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('hr',{staticClass:"line"}),_c('div',{staticClass:"create-reinsurers"},[_c('div',{staticClass:"catalog"},[_c('div',{staticClass:"catalog-select"},[_c(VAutocomplete,{attrs:{"label":"Registration reinsurers","items":_vm.catalogReinsurers,"item-value":"id","item-text":"type"},model:{value:(_vm.valueReinsurers),callback:function ($$v) {_vm.valueReinsurers=$$v},expression:"valueReinsurers"}})],1),_c('div',{staticClass:"button-cont"},[_c(VBtn,{staticClass:"btn",attrs:{"rounded":"","large":"","color":"#003D6D"},on:{"click":function($event){return _vm.createReinsurers()}}},[_vm._v(" Create reinsurer ")])],1)])]),(!_vm.disabledCards)?_c('div',{staticClass:"cards-reinsurer"},_vm._l((_vm.cards),function(reinsurer){return _c(VCard,{key:reinsurer.id,staticClass:"margin_cards",attrs:{"width":"24%","height":"12.5rem"}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('div',{staticClass:"text-wrap text-reinsurer"},[_vm._v(" Reinsurer: ")]),_c('div',{staticClass:"text-wrap text-reinsurer"},[_vm._v(" "+_vm._s(reinsurer.name)+" ")]),_c(VListItemSubtitle,[_vm._v(_vm._s(reinsurer.inceptionDate))])],1)],1),_c(VCardActions,[_c('router-link',{staticClass:"direction-button",attrs:{"to":("/reinsurers/" + (reinsurer.id))}},[_c(VBtn,{staticClass:"color-reinsurer mx-auto",attrs:{"width":"12rem","outlined":"","rounded":"","text":"","color":"white"}},[_vm._v(" See reinsurer ")])],1)],1)],1)}),1):_vm._e(),(_vm.reinsurersType === 'New contract')?_c('NewContract'):_vm._e(),(_vm.reinsurersType === 'New re-insurance DIC')?_c('NewReInsuranceDic'):_vm._e(),(_vm.reinsurersType === 'New direct re-insurance')?_c('NewDirectReInsurance'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }