export default {
  underwriter: ({ underwriters }) => underwriters,
  statusEng: ({ status_eng }) => status_eng,
  deductionsType: ({ deductions_type }) => deductions_type,
  underwriterMonth: ({ underwriter_month }) => underwriter_month,
  typeCoverage: ({ type_coverages }) => type_coverages,
  underlyingCat: ({ underlying_cats }) => underlying_cats,
  underlyingCatAplica: ({ underlying_cat_aplica }) => underlying_cat_aplica,
  underlyingCatValues: ({ underlying_cat_values }) => underlying_cat_values,
  underlyingFire: ({ underlying_fire }) => underlying_fire,
  underlyingFireAplica: ({ underlying_fire_aplica }) => underlying_fire_aplica,
  sublimits: ({ sublimits }) => sublimits,
  endoso: ({ endoso }) => endoso,
  otherDeductibles: ({ other_deductibles }) => other_deductibles,
  typeClause: ({ type_clause }) => type_clause,
  exposure: ({ exposure }) => exposure,
  housekeeping: ({ housekeeping }) => housekeeping,
  applySir: ({ apply_sir }) => apply_sir,
  businessBound: ({ business_bound }) => business_bound,
  underlyingFireQuotator: ({ underlying_fire_quotator }) => underlying_fire_quotator,
  razon_claim: ({ razon_claim }) => razon_claim,
};
