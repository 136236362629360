import { render, staticRenderFns } from "./PremiumPaymentWarranty.vue?vue&type=template&id=9f7e0d98&scoped=true&"
import script from "./PremiumPaymentWarranty.vue?vue&type=script&lang=js&"
export * from "./PremiumPaymentWarranty.vue?vue&type=script&lang=js&"
import style0 from "./PremiumPaymentWarranty.vue?vue&type=style&index=0&id=9f7e0d98&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f7e0d98",
  null
  
)

export default component.exports