export default {
  checkPayment: {
    value1: {
      active: false,
      paymentDate: '',
      porcentaje: '',
      value: '',
    },

    value2: {
      active: false,
      paymentDate: '',
      porcentaje: '',
      value: '',
    },

    value3: {
      active: false,
      paymentDate: '',
      porcentaje: '',
      value: '',
    },
  },
};
