export default {
  rates: {
    eqRate: 0,
    hydroRate: 0,
    floodRate: 0,
    otherRate: 0,
    nonCatFinalRate: 0,
    nonCatFinalPremiumRate: 0
  },
  ratesObj: {
    eqRate1: '0',
    hydro1:  '0',
    flood1:  '0',
    other1:  '0',

    eqRate2: '0',
    hydro2:  '0',
    flood2:  '0',
    other2:  '0',

    eqRate3: '0',
    hydro3:  '0',
    flood3:  '0',
    other3:  '0',

    eqRate4: '0',
    hydro4:  '0',
    flood4:  '0',
    other4:  '0',

    eqRateSelect: '',
    hydroSelect: '',
    floodSelect: '',
    otherSelect: '',
  },
  catRatesGroup: [
    {
      id: '',
      earthquake: '',
      hidro: '',
      flood: '',
      other: '',
      earthquakeRate: '',
      windRate: '',
      floodRate: '',
      otherRate: '',
      country: '',
      rate: '',
    },
  ],
  nonRates: [
    {
      id: '',
      activity: '',
      activityRate: '',
      rate: '',
      credit: '',
      reason: '',
      finalRate: '',
      premiumRate: '',
    },
  ],
  alopRates: {
    month: '',
    credit: '',
    floodRate: '',
    reasonsSelect: '',
    alopSelect: '',
    factorAlop: '',
    finalRate: '',
  },
  nonCatLarge: {
    rateSize: '',
    rateTime: '',
    credit: '',
    contractorSelect: '',
    categorySelect: '',
    rateContractor: '',
    typeConstructorSelect: '',
    ratetypeConstructor: '',
    testingSelect: '',
    ratetesting: '',
    equipmentInstallationSelect: '',
    rateequipmentInstallation: '',
    thirdPartyLiabilitySelect: '',
    ratethirdPartyLiability: '',
    firstyearOpSelect: '',
    ratefirstyearOp: '',
    maintenancePeriodSelect: '',
    ratemaintenancePeriod: '',
    inlandTransitSelect: '',
    rateinlandTransit: '',
    srccSelect: '',
    rateSrcc: '',
    errorsDesignSelect: '',
    rateerrorsDesign: '',
    reasonsSelect: '',
    sumMonthlyRate: '',
    finalRate: '',
  },
};
