export default {
  addCompany: {
    name: '',
    type: '',
    phone: '',
    address: '',
    country: '',
  },
  companies: [],
  submittedAddCompany: false,
}