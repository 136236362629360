import { render, staticRenderFns } from "./ChangeOfShare.vue?vue&type=template&id=6e6468ee&scoped=true&"
import script from "./ChangeOfShare.vue?vue&type=script&lang=js&"
export * from "./ChangeOfShare.vue?vue&type=script&lang=js&"
import style0 from "./ChangeOfShare.vue?vue&type=style&index=0&id=6e6468ee&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e6468ee",
  null
  
)

export default component.exports