import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"closeCont d-flex justify-center align-center"},[_c(VMenu,{attrs:{"z-index":"3000","offset-x":_vm.offset},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!(_vm.close_account.length === 0 || !_vm.subscription_id))?_c(VBtn,_vm._g(_vm._b({staticClass:"activateButton",attrs:{"rounded":"","text":"","large":"","loading":_vm.loading}},'v-btn',attrs,false),on),[_c('div',{staticClass:"ButtonText"},[_vm._v("Close Account")]),_c(VIcon,[_vm._v(" mdi-close-circle ")])],1):_vm._e()]}}])},[_c(VList,{staticClass:"pl-5 pr-5"},[_c('div',{staticClass:"headerClose d-flex justify-start align-center"},[_vm._v(" Reason to close account ")]),_vm._l((_vm.close_account),function(item,index){return _c('div',{key:index,staticClass:"button",on:{"click":function($event){return _vm.CloseAccount(item)}}},[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.description)+" ")])],1)})],2)],1)],1),_c('EmailModal',{ref:"emailModal",attrs:{"headerEmail":_vm.reason}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }