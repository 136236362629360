import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"targetRef",staticClass:"outter-wrapper"},[_c('div',{staticClass:"endorsement-title"},[_vm._v("Movement without premium")]),(_vm.accountComplete.reference)?_c('div',{staticClass:"editAccount"},[_vm._v(" "+_vm._s(_vm.accountComplete.reference)+" ")]):_vm._e(),_c(VStepper,{model:{value:(_vm.e1),callback:function ($$v) {_vm.e1=$$v},expression:"e1"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":_vm.e1 > 1,"step":"1","color":"#F59607"}},[_vm._v(" Endorsement ")])],1),_c('div',{staticClass:"endorsement-wrapper"},[_c('div',{staticClass:"content"},[_c(VStepperItems,[_c(VStepperContent,{staticClass:"step-one",attrs:{"step":"1"}},[_c('div',{staticClass:"input-row w-100 d-flex flex-wrap"},[_c('div',{staticClass:"input-col"},[_c('div',{staticClass:"input-cont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Endorsement effective date","readonly":""},model:{value:(_vm.effectiveDate),callback:function ($$v) {_vm.effectiveDate=$$v},expression:"effectiveDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu2 = false},"change":function($event){return _vm.endorsementDateValidation($event, _vm.effectiveDate)}},model:{value:(_vm.effectiveDate),callback:function ($$v) {_vm.effectiveDate=$$v},expression:"effectiveDate"}})],1),(this.endorsementDateError)?_c('div',{staticClass:"error-message"},[_vm._v(" The new Endorsement effective date must be lower than expiry date. ")]):_vm._e()],1)]),_c('div',{staticClass:"input-col"},[_c('div',{staticClass:"input-cont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Expiry date","readonly":"","disabled":""},model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}})],1)]),_c('InputDaysDiference',{key:_vm.effectiveDate,attrs:{"endorsementDate":_vm.effectiveDate,"expiryDate":_vm.expiryDatetoCalc}})],1),(_vm.showInfoEndorsement)?_c('div',[_c('div',{staticClass:"endorsement-title"},[_vm._v("Description")]),_c('div',{staticClass:"textArea-cont"},[_c(VTextarea,{staticClass:"textArea",attrs:{"background-color":"#EDF2F8","height":"180","solo":"","flat":"","rounded":"","no-resize":"","counter":"500"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('div',{staticClass:"input-row w-100 d-flex flex-wrap"},[_c('div',{staticClass:"input-col"},[_c('div',{staticClass:"inner-title"},[_vm._v("Additional")]),_c('div',{staticClass:"input-cont"},[_c(VAutocomplete,{attrs:{"label":"Clause","items":_vm.clauseList,"item-value":"clause","item-text":"clause"},model:{value:(_vm.clause),callback:function ($$v) {_vm.clause=$$v},expression:"clause"}})],1)]),_c('div',{staticClass:"input-col"},[_c('div',{staticClass:"inner-title",staticStyle:{"opacity":"0"}},[_vm._v("|")]),_c('div',{staticClass:"input-cont"},[_c(VMenu,{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"label":"Premium payment date"},model:{value:(_vm.premiumPaymentDate),callback:function ($$v) {_vm.premiumPaymentDate=$$v},expression:"premiumPaymentDate"}},'v-text-field',attrs,false),on))]}}],null,false,916333201),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c(VDatePicker,{on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.premiumPaymentDate),callback:function ($$v) {_vm.premiumPaymentDate=$$v},expression:"premiumPaymentDate"}})],1)],1)])])]):_vm._e()])],1)],1)]),_c('EndorsementDocuments',{directives:[{name:"show",rawName:"v-show",value:(_vm.e1 == 1 || _vm.e1 == 3),expression:"e1 == 1 || e1 == 3"}],on:{"setEndorsementDocuments":_vm.setEndorsementDocuments}}),_c('div',{staticClass:"stepper-btn mt-7 mb-3 d-flex justify-end align-center"},[_c(VBtn,{class:_vm.e1 == 3 ? 'blue-btn' : 'clear-btn',attrs:{"outlined":_vm.e1 == 3 ? false : true,"rounded":"","large":"","text":_vm.e1 == 3 ? true : false,"color":_vm.e1 == 3 ? 'none' : '#003D6D'},on:{"click":function($event){return _vm.goNext(_vm.e1)}}},[_vm._v(" "+_vm._s(_vm.buttonTitle)+" ")])],1),_c('div',{staticClass:"stepper-btn mb-7 d-flex justify-end align-center"},[_c(VBtn,{staticClass:"blue-btn",attrs:{"rounded":"","large":"","text":""},on:{"click":function($event){return _vm.goBack(_vm.e1)}}},[_vm._v(" "+_vm._s(_vm.buttonTitleBack)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }